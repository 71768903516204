module.exports = function hero() {
    let bg = document.querySelector('#bg')
    let moon = document.querySelector('#moon')
    let mountain = document.querySelector('#mountain')
    let road = document.querySelector('#road')
    let title = document.querySelector('#title')

    window, addEventListener('scroll', () => {
        let value = window.scrollY;
        bg.style.top = `${value * 0.5}px`
        moon.style.left = `${-value * 0.4}px`
        mountain.style.top = `${-value * 0.15}px`
        road.style.top = `${value * 0.15}px`
        title.style.top = `${value}px`
    })
    // const img = new Image();
    // img.src = "./assets/images/hero1.jpg";
    // img.onload = () => {
    //     document
    //         .querySelector(".hero")
    //         .setAttribute("style", `background-image: url(${img.src});`);
    //     document.querySelector('.hero-description').style.opacity = 1;
    //     // document.querySelector(".hero").classList.add("loaded");
    // };

};