// module.exports = function menu() {
//     var logo = document.querySelector('.header-logo');
//     var lastScroll = 0;
//     var burger = document.querySelector('.burger');
//     var nav = document.querySelector('.navigation');


//     nav.classList.add('navigation-active');
//     burger.classList.add('burger-load');
//     burger.style.opacity = '1';
//     logo.classList.add('header-logo-load');
//     logo.style.opacity = '1';

//     //burger
//     burger.addEventListener('click', () => {
//         burger.classList.toggle('burger-active');
//         document.querySelector('nav').classList.toggle('nav-active');
//     })
//     //показ хедера при скороле вверх
//     window.addEventListener('scroll', () => {

//         var scrollTop = window.pageYOffset;
//         if (lastScroll > scrollTop) {
//             document.querySelector('header').style.top = '0px'
//         } else if (lastScroll < scrollTop) {
//             document.querySelector('header').style.top = '-70px'
//         }
//         lastScroll = scrollTop;

//     })
// };

var dropBtn = document.querySelectorAll('.dropBtn');
var dropMenu = document.querySelectorAll('.dropMenu');
var nav = document.querySelector('.navigation');
module.exports = function menu() {


    //всплывающее меню при размере экрана больше 768px 
    if (window.innerWidth > 768) {

        for (let i = 0; i < dropBtn.length; i++) {

            dropBtn[i].addEventListener('click', () => {

                if (dropBtn[i].classList.contains('dropBtn-active')) {

                    dropBtn[i].classList.remove('dropBtn-active');
                    for (let i = 0; i < dropMenu.length; i++) {
                        dropMenu[i].classList.remove('dropMenu-active');
                    }

                } else {

                    for (let i = 0; i < dropBtn.length; i++) {
                        dropBtn[i].classList.remove('dropBtn-active');
                    }
                    dropBtn[i].classList.add('dropBtn-active');

                }

                for (let i = 0; i < dropBtn.length; i++) {
                    if (dropBtn[i].classList.contains('dropBtn-active')) {
                        for (let i = 0; i < dropMenu.length; i++) {
                            dropMenu[i].classList.remove('dropMenu-active');
                        }
                        dropMenu[i].classList.add('dropMenu-active');
                    } else {
                    }

                }
            })
        }
    }

    if (window.innerWidth <= 768) {
        var burger = document.querySelector('.burger');
        //вносим выпадающее меню внутрь кнопок (для удобства со стилями)
        for (let i = 0; i < dropBtn.length; i++) {
            dropBtn[i].appendChild(dropMenu[i]);
            dropMenu[i].classList.remove("container");
        }

        //показываем меню
        burger.addEventListener('click', () => {
            burger.classList.toggle('burger-active');
            nav.classList.toggle('navigation-active');
        })


        //разворачиваем меню
        for (let i = 0; i < dropBtn.length; i++) {
            dropBtn[i].addEventListener('click', () => {
                dropMenu[i].classList.toggle('dropMenu-active');
            })
        }
    }
}


